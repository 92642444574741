export const FONT_COLOR_WHITE = '#ffffff';
export const FONT_COLOR_DARK = '#091329';
export const SRW_COLOR_PRIMARY = '#008190';
export const SRW_COLOR_SECONDARY = '#091329';
export const SRW_COLOR_TERTIARY = '#F39237';

export const SRW_COLOR_PAPER = '#F2F9F9';
export const SRW_COLOR_PRIMARY_LIGHT = '#99CCD2';

export const SRW_LIGHT_GREEN = '#f0f6f7';

import config from 'next/config';
const NEXT_GOOGLE_ANALYTICS_ID = config().publicRuntimeConfig.NEXT_GOOGLE_ANALYTICS_ID;

export const gaSet = !!NEXT_GOOGLE_ANALYTICS_ID;

// log the pageview with their URL
export const pageview = (url: URL) => {
  const id = NEXT_GOOGLE_ANALYTICS_ID;
  if (id) {
    window.gtag('config', id, {
      page_path: url,
    });
  }
};

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

// log specific events happening.
export const event = ({ action, category, label, value }: GTagEvent) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

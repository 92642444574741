/* eslint-disable @next/next/no-img-element */
// for Lead forensics
import '../styles/globals.scss';
import type { AppContext, AppProps } from 'next/app';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import theme from '../theme';
import createEmotionCache from '../utils/createEmotionCache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import * as gtag from '../utils/ga';
import { createClient } from '../prismicio';

const clientSideEmotionCache = createEmotionCache();

type Props = AppProps & {
  props: {
    menu: any;
    footer: any;
  };
  emotionCache?: EmotionCache;
};

function SRWApp({
  Component,
  pageProps,
  props,
  emotionCache = clientSideEmotionCache,
}: Props) {
  const router = useRouter();

  useEffect(() => {
    if (gtag.gaSet) {
      const handleRouteChange = (url: URL) => {
        gtag.pageview(url);
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router.events]);

  return (
    <CacheProvider value={emotionCache}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...pageProps} menu={props.menu} footer={props.footer} />
          {/* Lead Forensics */}
          <noscript>
            <img
              alt=""
              src="https://secure.inventiveperception365.com/788810.png"
              style={{ display: 'none' }}
            />
          </noscript>
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  );
}

SRWApp.getInitialProps = async (_appContext: AppContext) => {
  const client = createClient();
  const menu = (await client.getSingle('menu', {})) || {};
  const footer = (await client.getSingle('footer', {})) || {};
  return {
    props: {
      menu,
      footer,
    },
  };
};

export default SRWApp;

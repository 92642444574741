import { createTheme, ThemeOptions } from '@mui/material/styles';
import { breakpoints } from './breakpoint';
import { buttonOverrides } from './buttonOverrides';
import {
  SRW_COLOR_PRIMARY,
  FONT_COLOR_WHITE,
  SRW_COLOR_SECONDARY,
  SRW_COLOR_TERTIARY,
  SRW_COLOR_PAPER,
  FONT_COLOR_DARK,
} from './color';
import {
  SRW_FONT_FAMILY,
  SRW_FONT_BOLD,
  SRW_FONT_REGULAR,
  SRW_FONT_MEDIUM,
  SRW_FONT_WIDER,
  SRW_FONT_WIDE,
} from './fonts';

let theme = createTheme({
  palette: {
    primary: {
      main: SRW_COLOR_PRIMARY,
      contrastText: FONT_COLOR_WHITE,
    },
    secondary: {
      main: SRW_COLOR_SECONDARY,
      contrastText: FONT_COLOR_WHITE,
    },
    tertiary: {
      main: SRW_COLOR_TERTIARY,
      contrastText: FONT_COLOR_WHITE,
    },
    light: {
      main: SRW_COLOR_PAPER,
      contrastText: FONT_COLOR_DARK,
    },
    dark: {
      main: SRW_COLOR_SECONDARY,
      contrastText: FONT_COLOR_WHITE,
    },
    action: {
      disabled: '#D5D7F7',
      disabledBackground: 'rgba(0, 0, 0, 0.03)',
    },
    text: {
      primary: FONT_COLOR_DARK,
      secondary: FONT_COLOR_WHITE,
    },
  },
  typography: {
    fontFamily: SRW_FONT_FAMILY,
    h1: {
      fontWeight: SRW_FONT_BOLD,
      fontSize: '48px',
      lineHeight: '58px',
      [breakpoints.only('sm')]: {
        fontSize: '58px',
        lineHeight: '70px',
      },
      [breakpoints.up('md')]: {
        fontSize: '70px',
        lineHeight: '80px',
      },
    },
    h2: {
      fontWeight: SRW_FONT_BOLD,
      fontSize: '36px',
      lineHeight: '48px',
      [breakpoints.only('sm')]: {
        fontSize: '56px',
        lineHeight: '68px',
      },
      [breakpoints.up('md')]: {
        fontSize: '60px',
        lineHeight: '72px',
      },
    },
    h3: {
      fontWeight: SRW_FONT_BOLD,
      fontSize: '34px',
      lineHeight: '44px',
      [breakpoints.only('sm')]: {
        fontSize: '36px',
        lineHeight: '48px',
      },
      [breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: '52px',
      },
    },
    h4: {
      fontWeight: SRW_FONT_BOLD,
      fontSize: '22px',
      lineHeight: '32px',
      [breakpoints.up('md')]: {
        fontSize: '32px',
        lineHeight: '42px',
      },
    },
    h5: {
      fontWeight: SRW_FONT_REGULAR,
      fontSize: '17px',
      lineHeight: '28px',
      [breakpoints.only('sm')]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
      [breakpoints.up('md')]: {
        fontSize: '22px',
        lineHeight: '32px',
      },
    },
    h6: {
      fontWeight: SRW_FONT_BOLD,
      fontSize: '15px',
      lineHeight: '25px',
      [breakpoints.up('md')]: {
        fontSize: '17px',
        lineHeight: '29px',
      },
    },
    body1: {
      fontWeight: SRW_FONT_REGULAR,
      fontSize: '15px',
      lineHeight: '27px',
      [breakpoints.up('md')]: {
        fontSize: '17px',
        lineHeight: '29px',
      },
    },
    body2: {
      fontSize: '14px',
      fontWeight: SRW_FONT_REGULAR,
      lineHeight: '26px',
    },
    body3: {
      fontWeight: SRW_FONT_REGULAR,
      fontSize: '12px',
      lineHeight: '22px',
    },
    body4: {
      fontSize: '14px',
      fontWeight: SRW_FONT_REGULAR,
      lineHeight: '26px',
      letterSpacing: SRW_FONT_WIDE,
    },
    body5: {
      fontSize: '14px',
      fontWeight: SRW_FONT_REGULAR,
      lineHeight: '26px',
      letterSpacing: SRW_FONT_WIDER,
    },
    subtitle1: {
      fontWeight: SRW_FONT_REGULAR,
      fontSize: '17px',
      lineHeight: '28px',
      [breakpoints.only('sm')]: {
        fontSize: '26px',
        lineHeight: '36px',
      },
      [breakpoints.up('md')]: {
        fontSize: '28px',
        lineHeight: '42px',
      },
    },
    subtitle2: {
      fontWeight: SRW_FONT_REGULAR,
      fontSize: '12px',
      lineHeight: '20px',
    },
    button: {
      fontWeight: SRW_FONT_MEDIUM,
      fontSize: '14px',
      lineHeight: '20px',
    },
    caption: {
      fontWeight: SRW_FONT_MEDIUM,
      fontSize: '14px',
      lineHeight: '23px',
      letterSpacing: SRW_FONT_WIDER,
      textTransform: 'uppercase',
    },
  },
  breakpoints: { values: { xs: 0, sm: 680, md: 1000, lg: 1280, xl: 1920 } },
});

const componentOverrides: ThemeOptions = {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          transition: 'unset',
          boxShadow: 'unset',
        },
      },
    },
    MuiButton: buttonOverrides.components?.MuiButton,
    MuiSelect: {
      styleOverrides: {
        select: {
          height: '28px',
          'padding-left': '8px',
          'padding-top': ' 8px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '100%',
        },
      },
    },
  },
};

theme = createTheme(theme, componentOverrides);

export default theme;

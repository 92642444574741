import { ThemeOptions } from '@mui/material/styles';
import {
  FONT_COLOR_DARK,
  FONT_COLOR_WHITE,
  SRW_COLOR_PAPER,
  SRW_COLOR_PRIMARY,
  SRW_COLOR_SECONDARY,
  SRW_COLOR_TERTIARY,
} from './color';
import { SRW_FONT_WIDER } from './fonts';

export const buttonOverrides: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          whiteSpace: 'nowrap',
        },
        text: {
          letterSpacing: SRW_FONT_WIDER,
        },
        sizeMedium: {
          padding: '14px',
        },
      },
      variants: [
        {
          props: { color: 'light' },
          style: {
            color: FONT_COLOR_DARK,
          },
        },
        {
          props: { color: 'dark' },
          style: {
            color: FONT_COLOR_WHITE,
          },
        },
        {
          props: { variant: 'menu' },
          style: {
            backgroundColor: 'transparent',
            letterSpacing: SRW_FONT_WIDER,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: '100%',
              transform: 'scaleX(0)',
              height: '3px',
              bottom: '6px',
              left: '0',
              transformOrigin: 'bottom right',
              transition: 'transform 0.25s ease-out',
            },
            '&:hover': {
              '&::after': {
                transform: 'scaleX(1)',
                transformOrigin: 'bottom left',
              },
            },
          },
        },
        {
          props: { variant: 'menu', size: 'medium' },
          style: {
            minWidth: '0',
            padding: '14px 0',
            margin: '0 14px',
          },
        },
        {
          props: { variant: 'menu', color: 'dark' },
          style: {
            '&::after': {
              backgroundColor: FONT_COLOR_WHITE,
            },
          },
        },
        {
          props: { variant: 'menu', color: 'light' },
          style: {
            '&::after': {
              backgroundColor: FONT_COLOR_DARK,
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: `2px solid ${SRW_COLOR_PRIMARY}`,
            '&:hover': {
              border: `2px solid ${SRW_COLOR_PRIMARY}`,
              color: FONT_COLOR_WHITE,
              backgroundColor: SRW_COLOR_PRIMARY,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: SRW_COLOR_SECONDARY,
            border: `2px solid ${SRW_COLOR_PRIMARY}`,
            '&:hover': {
              border: `2px solid ${SRW_COLOR_PRIMARY}`,
              backgroundColor: SRW_COLOR_PRIMARY,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'inherit' },
          style: {
            backgroundColor: 'transparent',
            border: `2px solid ${FONT_COLOR_WHITE}`,
            '&:hover': {
              border: `2px solid ${SRW_COLOR_TERTIARY}`,
              color: FONT_COLOR_WHITE,
              backgroundColor: SRW_COLOR_TERTIARY,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'light' },
          style: {
            backgroundColor: SRW_COLOR_PAPER,
            border: `2px solid ${FONT_COLOR_DARK}`,
            '&:hover': {
              border: `2px solid ${SRW_COLOR_PRIMARY}`,
              color: FONT_COLOR_WHITE,
              backgroundColor: SRW_COLOR_PRIMARY,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'dark' },
          style: {
            backgroundColor: SRW_COLOR_SECONDARY,
            border: `2px solid ${FONT_COLOR_WHITE}`,
            '&:hover': {
              border: `2px solid ${SRW_COLOR_PRIMARY}`,
              backgroundColor: SRW_COLOR_PRIMARY,
            },
          },
        },
      ],
    },
  },
};
